import React from "react";
import ReactDOM from "react-dom/client";
import { Theme, presetGpnDefault } from "@consta/uikit/Theme";
import { TabsContext } from "./context/Navigation";
import { App } from "./App";
import "./index.css";
import { ProbabilityContext } from "./context/Probability";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Theme preset={presetGpnDefault}>
      <TabsContext>
        <ProbabilityContext>
          <App />
        </ProbabilityContext>
      </TabsContext>
    </Theme>
  </React.StrictMode>
);
