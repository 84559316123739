import { useState } from "react";
import { createCallableCtx } from "../lib/context";

type Tabs = "single" | "multiple" | "train";

const useHook = () => {
  const [state, setState] = useState<Tabs>("single");

  return { activeProbability: state, setActiveProbability: setState };
};

export const [useProbabilityContext, ProbabilityContext] = createCallableCtx(useHook, {
  name: "ProbabilityContext",
});
