import React from "react";
import { useTabsContext } from "../context/Navigation";

type Props = {
  className?: string;
};

export const AppLine: React.FC<Props> = ({ className }) => {
  const { activeTab } = useTabsContext();

  return (
    <div
      className={`h-px w-full ${
        activeTab === "expulsion"
          ? "bg-bg-quaternary3"
          : activeTab === "diploma"
          ? "bg-bg-quaternary2"
          : "bg-bg-quaternary"
      } ${className || ""}`}
    />
  );
};
