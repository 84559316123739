import React from "react";

const cards = [
  {
    title: "Точность предсказаний",
    description:
      "Наша модель демонстрирует точность на 4% выше, чем у конкурентов. Выбирая нас, вы получаете более надежные прогнозы и уверенность в успехе.",
  },
  {
    title: "Индивидуальный подход",
    description:
      "AI Molodca анализирует стартовую информацию о каждом студенте, что позволяет адаптировать учебный процесс под конкретные потребности и способности учащихся.",
  },
  {
    title: "Эффективное управление",
    description:
      "Система помогает оптимизировать ресурсы образовательного учреждения, снижать риск отчислений и повышать общий уровень успеваемости студентов.",
  },
  {
    title: "Оптимизация",
    description:
      "Снижение рисков отчисления и повышенная успеваемость благодаря информированным решениям.",
  },
];

export const AboutSection: React.FC = () => {
  return (
    <div className="flex flex-col 2xl:flex-row gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12">
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 z-[-1] h-full w-full opacity-50"
          alt="AI Molodca"
        />
        <h2 className="z-1 text-[4rem]">Прогнозирование</h2>
        <p className="z-1 text-[2.5rem]">
          академической успеваемости студентов играет ключевую роль в
          образовательных учреждениях. Оно помогает идентифицировать учащихся,
          которые нуждаются в дополнительной поддержке, и способствует повышению
          качества образовательного процесса в целом.
        </p>
      </div>

      <div className="flex-1 shrink-0 flex flex-wrap gap-6 lg:gap-10">
        {cards.map((card) => (
          <div
            key={card.title}
            className="text-white tex-medium flex flex-col gap-6 p-6 w-full lg:w-[calc(50%-20px)] bg-bg-quaternary rounded-[40px]"
          >
            <h3 className="text-[2rem]">{card.title}</h3>
            <p className="text-[1.5rem]">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
