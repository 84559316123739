import React, { useState } from "react";
import { Button } from "@consta/uikit/Button";
import { RadioGroup } from "@consta/uikit/RadioGroup";
import { DatePicker } from "@consta/uikit/DatePicker";
import { CollapseGroup } from "@consta/uikit/CollapseGroup";
import { Table } from "@consta/uikit/Table";
import { TextField } from "@consta/uikit/TextField";
import { Select } from "@consta/uikit/Select";
import { AppLine } from "../../../components/AppLine";
import "./SingleForm.css";

type Props = {};

const subjects = [
  "Информатика и ИКТ",
  "Математика",
  "Русский язык",
  "Обществознание",
  "Физика",
  "Химия",
  "Английский язык",
  "История",
  "Иностранный язык",
  "Французский язык",
  "Немецкий язык",
].map((item) => ({ label: item, id: item }));

function* getIdGenerator() {
  let id = 1;
  while (true) {
    yield id++;
  }
}

const idGenerator = getIdGenerator();

export const SingleForm = (props: Props) => {
  const [values, setValues] = useState<any>({
    ege: [{ id: idGenerator.next().value, subject: "", mark: "" }],
    marks: Array.from({ length: 5 }, (_, x) =>
      Array.from({ length: 2 }, (_, y) => ({
        label: `Семестр ${x * 2 + y + 1}`,
        dolg: "",
        marks: [{ id: idGenerator.next().value, subject: "", mark: "" }],
      }))
    ).flat(1),
  });

  const [result, setResult] = useState<
    { id: string; percent: number; mark: number }[]
  >([]);

  const uniqueEge = values.ege.filter(
    (item: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.subject === item.subject)
  );

  const onSubmit = (e: any) => {
    e.preventDefault();
    setResult([]);
    const firstDay = new Date(values.firstDay);
    const firstStudyDay = new Date(values.firstStudyDay);

    let result = [
      {
        Бакалавриат: values.academicLevel?.name === "Бакалавр",
        ...(Boolean(values.army) && {
          Военный_билет: values.army?.name === "Да",
        }),
        ...(Boolean(values.years) && { Возраст: Number(values.years?.id) }),
        Год_обучения: Number(values.course?.id) || 1,
        ...(Boolean(values.firstDay) && {
          Дата_зачисления_день: firstDay.getDate(),
          Дата_зачисления_месяц: firstDay.getMonth() + 1,
        }),
        ...(Boolean(values.firstStudyDay) && {
          Дата_начала_обучения_день: firstStudyDay.getDate(),
          Дата_начала_обучения_месяц: firstStudyDay.getMonth() + 1,
        }),
        ЕГЭ_английский_язык: Number(
          uniqueEge.find((item: any) => item.subject === "Английский язык")
            ?.mark?.id || 0
        ),
        ЕГЭ_иностранный_язык: Number(
          uniqueEge.find((item: any) => item.subject === "Иностранный язык")
            ?.mark?.id || 0
        ),
        ЕГЭ_информатика_и_икт: Number(
          uniqueEge.find((item: any) => item.subject === "Информатика и ИКТ")
            ?.mark?.id || 0
        ),
        ЕГЭ_история: Number(
          uniqueEge.find((item: any) => item.subject === "История")?.mark?.id ||
            0
        ),
        ЕГЭ_математика: Number(
          uniqueEge.find((item: any) => item.subject === "Математика")?.mark
            ?.id || 0
        ),
        ЕГЭ_немецкий_язык: Number(
          uniqueEge.find((item: any) => item.subject === "Немецкий язык")?.mark
            ?.id || 0
        ),
        ЕГЭ_обществознание: Number(
          uniqueEge.find((item: any) => item.subject === "Обществознание")?.mark
            ?.id || 0
        ),
        ЕГЭ_русский_язык: Number(
          uniqueEge.find((item: any) => item.subject === "Русский язык")?.mark
            ?.id || 0
        ),
        ЕГЭ_физика: Number(
          uniqueEge.find((item: any) => item.subject === "Физика")?.mark?.id ||
            0
        ),
        ЕГЭ_французский_язык: Number(
          uniqueEge.find((item: any) => item.subject === "Французский язык")
            ?.mark?.id || 0
        ),
        ЕГЭ_химия: Number(
          uniqueEge.find((item: any) => item.subject === "Химия")?.mark?.id || 0
        ),
        Из_России: values.fromRussia?.name === "Да",
        ...(Boolean(values.disciplinesCountFuture) && {
          Количество_дисциплин_в_семестре: Number(
            values.disciplinesCountFuture?.id
          ),
        }),
        Адрес: values.address,
        Школа_с_уклоном: values.school?.name === "Да",
        Колледж: values.college?.name === "Да",
        Льгота: values.army?.name === "Да",
        Магистратура: values.academicLevel?.name === "Магистр",
        Пол: values.sex?.name !== "Мужской",
        Полугодие: Number(values.semester?.id || 1),
        Практики: Number(values.practicesCount?.id || 0),
        Призер_олимпиад: values.olimpic?.name === "Да",
        Проживание: [1, 2].includes(values.studHome?.id),
        Покинул_общежитие: [2].includes(values.studHome?.id),
        Специалитет: values.academicLevel?.name === "Специалитет",
        Стипендия: values.money?.name === "Да",
        Форма_обучения: values.studForm?.name === "Очная",
        Количество_дисциплин_1_1: Number(values.marks[0].marks?.length || 0),
        Количество_дисциплин_1_2: Number(values.marks[1].marks?.length || 0),
        Количество_дисциплин_2_1: Number(values.marks[2].marks?.length || 0),
        Количество_дисциплин_2_2: Number(values.marks[3].marks?.length || 0),
        Количество_дисциплин_3_1: Number(values.marks[4].marks?.length || 0),
        Количество_дисциплин_3_2: Number(values.marks[5].marks?.length || 0),
        Количество_дисциплин_4_1: Number(values.marks[6].marks?.length || 0),
        Количество_дисциплин_4_2: Number(values.marks[7].marks?.length || 0),
        Количество_дисциплин_5_1: Number(values.marks[8].marks?.length || 0),
        Количество_дисциплин_5_2: Number(values.marks[9].marks?.length || 0),
        Количество_долго_1_1: Number(values.marks[0].dolg?.id || 0),
        Количество_долго_1_2: Number(values.marks[1].dolg?.id || 0),
        Количество_долго_2_1: Number(values.marks[2].dolg?.id || 0),
        Количество_долго_2_2: Number(values.marks[3].dolg?.id || 0),
        Количество_долго_3_1: Number(values.marks[4].dolg?.id || 0),
        Количество_долго_3_2: Number(values.marks[5].dolg?.id || 0),
        Количество_долго_4_1: Number(values.marks[6].dolg?.id || 0),
        Количество_долго_4_2: Number(values.marks[7].dolg?.id || 0),
        Количество_долго_5_1: Number(values.marks[8].dolg?.id || 0),
        Количество_долго_5_2: Number(values.marks[9].dolg?.id || 0),

        Оценка_1_1: Number(
          values.marks[0].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[0].marks?.length || 0
        ),
        Оценка_1_2: Number(
          values.marks[1].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[1].marks?.length || 0
        ),
        Оценка_2_1: Number(
          values.marks[2].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[2].marks?.length || 0
        ),
        Оценка_2_2: Number(
          values.marks[3].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[3].marks?.length || 0
        ),
        Оценка_3_1: Number(
          values.marks[4].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[4].marks?.length || 0
        ),
        Оценка_3_2: Number(
          values.marks[5].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[5].marks?.length || 0
        ),
        Оценка_4_1: Number(
          values.marks[6].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[6].marks?.length || 0
        ),
        Оценка_4_2: Number(
          values.marks[7].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[7].marks?.length || 0
        ),
        Оценка_5_1: Number(
          values.marks[8].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[8].marks?.length || 0
        ),
        Оценка_5_2: Number(
          values.marks[9].marks.reduce(
            (acc: number, cur: any) => acc + Number(cur) || 0,
            0
          ) / values.marks[9].marks?.length || 0
        ),
      },
    ];

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(result);

    fetch("https://students.zwerruga.ru/api/v1/predict-mass/red-diploma", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((response) =>
        setResult(
          response?.message?.map((item: number, i: number) => {
            return {
              mark: item || 0,
              percent: `${response.percents[i].toFixed(2) || 0}%`,
              id: idGenerator.next().value,
            };
          })
        )
      )
      .catch((error) => console.error(error));
  };

  const preparedMarks = values.marks
    .slice(0, (values.course?.id - 1) * 2 + values.semester?.id - 1)
    .map((item: any) => ({
      ...item,
      marks: item.marks.map((child: any, i: any) => (
        <div
          className="flex lg:items-center flex-wrap lg:flex-nowrap max-lg:flex-col w-full justify-between gap-6"
          key={`${item.label}${i}`}
        >
          <TextField
            value={child.subject}
            className="shrink-0 flex-1"
            labelPosition="left"
            onChange={(value) =>
              setValues((v: any) => ({
                ...v,
                marks: v.marks.map((innerItem: any) => {
                  if (innerItem.label === item.label) {
                    return {
                      ...innerItem,
                      marks: innerItem.marks.map((inInnerItem: any) => {
                        if (inInnerItem.id === child.id) {
                          return {
                            ...inInnerItem,
                            subject: value || "",
                          };
                        }

                        return inInnerItem;
                      }),
                    };
                  }

                  return innerItem;
                }),
              }))
            }
            type="text"
            size="l"
            label={`${i + 1}. Название предмета:`}
            placeholder="Введите предмет"
          />

          <Select
            label="Оценка:"
            placeholder="5"
            className="shrink-0 w-[200px] small-label"
            labelPosition="left"
            items={Array.from({ length: 5 }, (_, i) => ({
              label: String(i + 1),
              id: String(i + 1),
            }))}
            value={child.mark}
            onChange={(value) =>
              setValues((v: any) => ({
                ...v,
                marks: v.marks.map((innerItem: any) => {
                  if (innerItem.label === item.label) {
                    return {
                      ...innerItem,
                      marks: innerItem.marks.map((inInnerItem: any) => {
                        if (inInnerItem.id === child.id) {
                          return {
                            ...inInnerItem,
                            mark: value,
                          };
                        }

                        return inInnerItem;
                      }),
                    };
                  }

                  return innerItem;
                }),
              }))
            }
            size="l"
          />
        </div>
      )),
    }));
  const items = [
    {
      label: "Общая информация",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <Select
            label="Возраст:"
            placeholder="Введите свой возраст"
            className="shrink-0"
            value={values.years}
            labelPosition="left"
            items={Array.from({ length: 43 }, (_, i) => ({
              label: String(i + 18),
              id: String(i + 18),
            }))}
            size="l"
            onChange={(value) =>
              setValues((v: any) => ({ ...v, years: value }))
            }
          />

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Пол: <span className="FieldLabel-Star">*</span>
            </label>
            <RadioGroup
              value={values.sex}
              className="ml-2"
              name="sex"
              direction="row"
              size="l"
              items={[
                {
                  name: "Мужской",
                },
                {
                  name: "Женский",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, sex: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">Из России:</label>
            <RadioGroup
              value={values.fromRussia}
              className="ml-2"
              name="fromRussia"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, fromRussia: value }))
              }
            />
          </div>

          <TextField
            value={values.address || ""}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, address: value }))
            }
            labelPosition="left"
            name="address"
            type="text"
            size="l"
            label="Адрес:"
            min={18}
            max={60}
            placeholder="Напишите адрес проживания в формате: Москва, Россия"
          />

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Военный билет:
            </label>
            <RadioGroup
              value={values.army}
              className="ml-2"
              name="army"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, army: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Школа с уклоном:
            </label>
            <RadioGroup
              value={values.school}
              className="ml-2"
              name="school"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, school: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">Колледж:</label>
            <RadioGroup
              value={values.college}
              className="ml-2"
              name="college"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, college: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Призер олимпиад:
            </label>
            <RadioGroup
              value={values.olimpic}
              className="ml-2"
              name="olimpic"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, olimpic: value }))
              }
            />
          </div>
        </div>
      ),
    },
    {
      label: "ЕГЭ",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px] max-lg:flex-col w-full py-6 px-10">
          {uniqueEge.map((item: any, i: number) => (
            <div
              className="flex lg:items-center flex-wrap lg:flex-nowrap max-lg:flex-col w-full justify-between gap-6"
              key={`${item.id}${i}`}
            >
              <Select
                label={`${i + 1}. Название предмета:`}
                className="shrink-0 flex-1"
                placeholder="Выберите предмет"
                labelPosition="left"
                value={{ label: item.subject, id: item.subject }}
                items={subjects}
                size="l"
                onChange={(value) =>
                  setValues((v: any) => ({
                    ...v,
                    ege: v.ege.map((innerItem: any) => {
                      if (innerItem.id === item.id) {
                        return { ...innerItem, subject: value?.label };
                      }

                      return innerItem;
                    }),
                  }))
                }
              />

              <Select
                label="Оценка:"
                placeholder="100"
                labelPosition="left"
                className="shrink-0 w-[200px] small-label"
                items={Array.from({ length: 100 }, (_, i) => ({
                  label: String(100 - i),
                  id: String(100 - i),
                }))}
                value={item.mark}
                onChange={(value) =>
                  setValues((v: any) => ({
                    ...v,
                    ege: v.ege.map((innerItem: any) => {
                      if (innerItem.id === item.id) {
                        return {
                          ...innerItem,
                          mark: value,
                        };
                      }

                      return innerItem;
                    }),
                  }))
                }
                size="l"
              />
            </div>
          ))}

          <Button
            label="Добавить"
            className="w-fit"
            disabled={values.ege.some((item: any) => !item.subject)}
            onClick={() =>
              setValues((v: any) => ({
                ...v,
                ege: v.ege.concat({
                  id: idGenerator.next().value,
                  subject: "",
                  mark: "",
                }),
              }))
            }
          />
        </div>
      ),
    },
    {
      label: "ВУЗ",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Уровень образования:
            </label>
            <RadioGroup
              value={values.academicLevel}
              className="ml-2"
              name="academicLevel"
              direction="row"
              size="l"
              items={[
                {
                  name: "Бакалавр",
                },
                {
                  name: "Магистр",
                },
                {
                  name: "Специалитет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, academicLevel: value }))
              }
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Форма обучения:
            </label>
            <RadioGroup
              value={values.studForm}
              className="ml-2"
              name="studForm"
              direction="row"
              size="l"
              items={[
                {
                  name: "Очная",
                },
                {
                  name: "Очно-заочная",
                },
                {
                  name: "Заочная",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, studForm: value }))
              }
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Льготы при поступлении:
            </label>
            <RadioGroup
              value={values.bonuses}
              className="ml-2"
              name="bonuses"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, bonuses: value }))
              }
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Наличие стипендии:
            </label>
            <RadioGroup
              value={values.money}
              className="ml-2"
              name="money"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, money: value }))
              }
            />
          </div>

          <DatePicker
            label="Дата зачисления:"
            name="firstDay"
            labelPosition="left"
            value={values.firstDay}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, firstDay: value }))
            }
          />

          <DatePicker
            label="Дата начала обучения:"
            name="firstStudyDay"
            labelPosition="left"
            value={values.firstStudyDay}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, firstStudyDay: value }))
            }
          />

          <Select
            name="studHome"
            label="Общежитие:"
            placeholder="Выберите подходящий вариант"
            labelPosition="left"
            value={values.studHome}
            items={[
              {
                label: "Жил в общежитии и проживаю в данный момент",
                id: 1,
              },
              {
                label: "Жил в общежитие, на данный момент не проживаю",
                id: 2,
              },
              {
                label: "Не жил в общежитии и не планирую проживать",
                id: 3,
              },
              {
                label: "Не жил в общежитии, планирую там проживать",
                id: 4,
              },
            ]}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, studHome: value }))
            }
          />

          <Select
            name="practicesCount"
            label="Количество практик:"
            placeholder="0"
            value={values.practicesCount}
            labelPosition="left"
            items={Array.from({ length: 100 }, (_, i) => ({
              label: i,
              id: i,
            }))}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, practicesCount: value }))
            }
          />

          <Select
            name="course"
            required
            label="Курс:"
            placeholder="Выберите подходящий вариант"
            value={values.course}
            labelPosition="left"
            items={Array.from({ length: 5 }, (_, i) => ({
              label: i + 1,
              id: i + 1,
            }))}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, course: value }))
            }
          />

          <Select
            name="semester"
            required
            label="Полугодие:"
            placeholder="Выберите подходящий вариант"
            value={values.semester}
            labelPosition="left"
            items={Array.from({ length: 2 }, (_, i) => ({
              label: i + 1,
              id: i + 1,
            }))}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, semester: value }))
            }
          />
        </div>
      ),
    },
    {
      label: "Оценки",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <CollapseGroup
            items={preparedMarks}
            getItemLabel={(item: any) => item.label}
            getItemContent={(item: any) => (
              <div className="flex flex-col gap-8">
                <Select
                  label="Количество долгов:"
                  placeholder="0"
                  className="shrink-0 w-full"
                  labelPosition="left"
                  items={Array.from({ length: 101 }, (_, i) => ({
                    label: String(i),
                    id: String(i),
                  }))}
                  value={item.dolg}
                  onChange={(value) =>
                    setValues((v: any) => ({
                      ...v,
                      marks: v.marks.map((innerItem: any) => {
                        if (innerItem.label === item.label) {
                          return {
                            ...innerItem,
                            dolg: value,
                          };
                        }

                        return innerItem;
                      }),
                    }))
                  }
                  size="l"
                />

                {item.marks}
                <Button
                  label="Добавить"
                  className="w-fit"
                  onClick={() =>
                    setValues((v: any) => ({
                      ...v,
                      marks: v.marks.map((mark: any) =>
                        mark.label === item.label
                          ? {
                              ...mark,
                              marks: mark.marks.concat({
                                id: idGenerator.next().value,
                                subject: "",
                                mark: "",
                              }),
                            }
                          : mark
                      ),
                    }))
                  }
                />
              </div>
            )}
            size="m"
          />
        </div>
      ),
    },
    {
      label: "Дополнительная информация",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <Select
            label="Сколько дисциплин в семестре:"
            placeholder="0"
            className="shrink-0 w-full"
            labelPosition="left"
            items={Array.from({ length: 30 }, (_, i) => ({
              label: String(i),
              id: String(i),
            }))}
            value={values.disciplinesCountFuture}
            onChange={(value) =>
              setValues((v: any) => ({
                ...v,
                disciplinesCountFuture: value,
              }))
            }
            size="l"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <form
        method="POST"
        className="form-section2 flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full"
        onSubmit={onSubmit}
      >
        <CollapseGroup
          items={items}
          size="l"
          style={{ "--graphics-size-s": "4rem !important" } as any}
        />

        <button type="submit" className="form-section-button2">
          Узнать результат
        </button>
      </form>

      <AppLine />

      {result?.length > 0 && (
        <div className="result-section2 flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
          <div className="font-medium text-[1rem] md:text-[2rem] bg-bg-ternary2 text--bg-primary2 shadow-xl p-16 rounded-[2rem] bg-opacity-50 flex flex-col gap-8">
            <Table
              rows={result}
              columns={[
                {
                  title: "Группа дисциплин",
                  accessor: "id",
                },
                {
                  title: "Точность предсказания",
                  accessor: "percent",
                  width: 200,
                },
                {
                  title: "Вероятность",
                  accessor: "mark",
                  width: 100,
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};
