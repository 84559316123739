import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Marks } from "./pages/Marks";
import { Diploma } from "./pages/Diploma";
import { Expulsion } from "./pages/Expulsion";
import { AboutSection } from "./components/AboutSection";
import { AppLine } from "./components/AppLine";
import { ProbabilitySection } from "./components/ProbabilitySection";
import { useEffect } from "react";
import { useTabsContext } from "./context/Navigation";
import { AboutSection2 } from "./components/AboutSection2";
import { AboutSection3 } from "./components/AboutSection3";
import { ProbabilitySection3 } from "./components/ProbabilitySection3";
import { ProbabilitySection2 } from "./components/ProbabilitySection2";
import "./App.css";


const routes = [
  { path: "/", Component: Marks },
  { path: "/expulsion", Component: Expulsion },
  { path: "/diploma", Component: Diploma },
];

export const App = () => {
  const { activeTab } = useTabsContext();
  useEffect(() => {
    const className =
      activeTab === "expulsion"
        ? "bg-bg-secondary3"
        : activeTab === "diploma"
        ? "bg-bg-secondary2"
        : "bg-bg-secondary";
    document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, [activeTab]);

  return (
    <BrowserRouter>
      <div className="w-full mt-10 px-2 md:px-8 lg:px-10 xl:px-[1vw] 2xl:px-[5vw] flex flex-col items-center ">
        <Header />
        <main className="flex flex-col items-center w-full">
          {activeTab === "expulsion" ? (
            <AboutSection3 />
          ) : activeTab === "diploma" ? (
            <AboutSection2 />
          ) : (
            <AboutSection />
          )}
          <AppLine />
          {activeTab === "expulsion" ? (
            <ProbabilitySection3 />
          ) : activeTab === "diploma" ? (
            <ProbabilitySection2 />
          ) : (
            <ProbabilitySection />
          )}
          <AppLine />
          <Routes key={activeTab}>
            {routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
};
