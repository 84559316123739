import React from "react";

const cards = [
  {
    title: "Точность предсказаний",
    description:
      "Наша модель обеспечивает точность предсказаний на 4% выше по сравнению с конкурентами. Это означает более надежные прогнозы и уверенность в успешных результатах.",
  },
  {
    title: "Поддержка комплексного анализа",
    description:
      "AI Molodca предлагает возможность глубокой аналитики, включая визуализацию данных и детальные отчеты, что упрощает выявление ключевых факторов, влияющих на успеваемость студентов.",
  },
  {
    title: "Эффективное управление",
    description:
      "Система помогает образовательным учреждениям эффективно использовать ресурсы, минимизируя риск отчислений и способствуя улучшению успеваемости студентов.",
  },
  {
    title: "Оптимизация учебных процессов",
    description:
      "Благодаря использованию точных данных, наша модель поддерживает информированные решения, что ведет к снижению рисков отчислений и повышению академической успеваемости.",
  },
];

export const AboutSection2: React.FC = () => {
  return (
    <div className="flex flex-col 2xl:flex-row gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12 bg-bg-primary2">
        <h2 className="z-1 text-[4rem]">
          Прогнозирование вероятности получения красного диплома
        </h2>
        <p className="z-1 text-[2.5rem]">
          Наша модель помогает определить студентов, имеющих потенциал для
          получения красного диплома, что способствует мотивации и поддержке их
          академического роста. Это позволяет образовательным учреждениям
          эффективно распределять ресурсы для содействия выдающимся учащимся.
        </p>
      </div>

      <div className="flex-1 shrink-0 flex flex-wrap gap-6 lg:gap-10">
        {cards.map((card) => (
          <div
            key={card.title}
            className="text-white tex-medium flex flex-col gap-6 p-6 w-full lg:w-[calc(50%-20px)] bg-bg-primary2 rounded-[40px]"
          >
            <h3 className="text-[2rem]">{card.title}</h3>
            <p className="text-[1.5rem]">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
