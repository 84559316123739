import React from "react";
import "./ProbabilitySection.css";

export const ProbabilitySection: React.FC = () => {
  return (
    <div className="probability-section3 flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12 bg-bg-primary3">
        <img
          src="/expulsion-1.png"
          className="absolute top-[-100%] left-[50%] translate-x-[-50%] z-[-1] h-auto w-full opacity-50 bg-black"
          alt="AI Molodca"
        />

        <h2 className="z-1 text-[4rem]">Точная настройка для вас</h2>
        <p className="z-1 text-[2.5rem] pb-[10rem]">
          Отправьте заявку в данном разделе, чтобы помочь в дообучении модели.
          Ваша отдача делает нашу систему более интегрированной и полезной.
        </p>
      </div>
    </div>
  );
};
