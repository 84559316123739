import React from "react";
import { useProbabilityContext } from "../../context/Probability";
import { SingleForm } from "./components/SingleForm";
import { MultipleForm } from "./components/MultipleForm";
import { TrainForm } from "./components/TrainForm";
import { ProbabilitySection } from "./components/ProbabilitySection";
import { AppLine } from "../../components/AppLine";

export const Expulsion = () => {
  const { activeProbability } = useProbabilityContext();
  const component = {
    single: <SingleForm />,
    multiple: <MultipleForm />,
    train: <TrainForm />,
  }[activeProbability];

  return (
    <div className="w-full">
      {activeProbability === "train" && (
        <>
          <ProbabilitySection />
          <AppLine />
        </>
      )}
      {component}
    </div>
  );
};
