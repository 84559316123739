import { useState } from "react";
import { createCallableCtx } from "../lib/context";

type Tabs = "marks" | "diploma" | "expulsion";

const useHook = () => {
  const [state, setState] = useState<Tabs>("marks");

  return { activeTab: state, setActiveTab: setState };
};

export const [useTabsContext, TabsContext] = createCallableCtx(useHook, {
  name: "TabsContext",
});
