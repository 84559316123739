import React from "react";
import { useProbabilityContext } from "../context/Probability";
import "./ProbabilitySection3.css";

const routes = [
  { type: "single", name: "Единичное предсказание" },
  { type: "multiple", name: "Массовое предсказание" },
  { type: "train", name: "Дообучение модели" },
];

export const ProbabilitySection3: React.FC = () => {
  const { activeProbability, setActiveProbability } = useProbabilityContext();

  return (
    <div className="probability-section3 flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12 bg-bg-quaternary3">
        <img
          src="/expulsion-2.png"
          className="absolute top-[-100%] left-0 h-auto w-full opacity-[25%] bg-black object-cover"
          alt="AI Molodca"
        />

        <p className="z-1 text-[2.5rem] pb-[10rem]">
          Узнайте, как модель ИИ может предсказать риски и помочь учебному
          заведению сохранить учащихся. Начните использовать решение для
          поддержки академических показателей и сокращения числа отчислений.
        </p>
      </div>
      <div className="flex justify-between gap-6 lg:gap-10 flex-col lg:flex-row">
        {routes.map((route) => (
          <button
            key={route.name}
            className={`probability-section-button3 ${
              activeProbability === route.type
                ? "probability-section-button3_active"
                : ""
            }`}
            onClick={() => setActiveProbability(route.type as never)}
          >
            {route.name}
          </button>
        ))}
      </div>
    </div>
  );
};
